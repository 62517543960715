import { HTTP_API_ERROR } from "../../../constants/app-constants";
import { FINANCE_TYPE } from "../../../constants/checkout-constants";
import { ZERO_DP_VARIANTS } from "../../../constants/optimize-constants";
import { BffDataAU, ListingServiceAU, ListingServiceAUV2 } from "../../../service";
import getFiltersData from "../../../utils/filters-v2/get-filters-data";
import initAppliedFilters from "../../../utils/filters-v2/init-applied-filters";
import { updateAppliedFilters, updateSSRFiltersList } from "../filters/actions";
import Types from "./types";

const fetchCarListInit = () => ({
    type: Types.FETCH_CAR_LIST
});

const fetchCarListSuccess = (data, params) => ({
    type: Types.FETCH_CAR_LIST_SUCCESS,
    data,
    params
});

const fetchSimilarCarSuccess = (data, params) => ({
    type: Types.FETCH_SIMILAR_CAR_LIST_SUCCESS,
    data,
    params
});

const setKbbBannerData = (data) => ({
    type: Types.SET_KBB_BANNER_DATA,
    data
});

const fetchCarListFailure = (error) => ({ type: Types.FETCH_CAR_LIST_FAILURE, error });

const fetchCarList = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        queryString = `${queryString}&userFinanceType=${userFinanceType}`;
    }
    params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchListing(params, queryString, isGuestLogin ? null : secureToken)
            .then((response) => {
                fetchCarListSuccess(response.data, params);
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.selectedFilters) {
                    const parsedFilters = getFiltersData(response.data.selectedFilters, true);
                    const appliedFiltersData = initAppliedFilters(parsedFilters.allFilters);
                    dispatch(updateSSRFiltersList({ ...appliedFiltersData, isSSR: false }));
                }
                resolve(response.data);
            })
            .catch(() => {
                dispatch(fetchCarListFailure({error: HTTP_API_ERROR}));
                reject({error: HTTP_API_ERROR});
            });
    });
};

const fetchCarListV2 = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        queryString = `${queryString}&userFinanceType=${userFinanceType}`;
    }
    params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then((response) => {
                dispatch(fetchCarListSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.selectedFilters) {
                    const parsedFilters = getFiltersData(response.data.selectedFilters, true);
                    const appliedFiltersData = initAppliedFilters(parsedFilters.allFilters);
                    dispatch(updateSSRFiltersList({ ...appliedFiltersData, isSSR: false }));
                }
                resolve(response.data);
            })
            .catch(() => {
                dispatch(fetchCarListFailure({error: HTTP_API_ERROR}));
                reject({error: HTTP_API_ERROR});
            });
    });
};

const fetchCarSuggestions = (params, queryString) => (dispatch, getState) => {
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        queryString = `${queryString}&userFinanceType=${userFinanceType}`;
    }
    params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then((response) => {
                resolve(response.data);
            })
            .catch(() => {
                reject({error: HTTP_API_ERROR});
            });
    });
};

const fetchSimilarCarListInit = () => ({
    type: Types.FETCH_SIMILAR_CAR_LIST
});

const fetchSimilarCarListFailure = (error) => ({ type: Types.FETCH_SIMILAR_CAR_LIST_FAILURE, error });

const fetchSimilarCarListV2 = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchSimilarCarListInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        const userFinanceType = FINANCE_TYPE.CARS24;
        queryString = `${queryString}&userFinanceType=${userFinanceType}`;
    }
    params = {zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP, ...params};
    return new Promise((resolve, reject) => {
        ListingServiceAUV2.fetchListingV2(params, queryString, isGuestLogin ? null : secureToken)
            .then((response) => {
                dispatch(fetchSimilarCarSuccess(response.data, params));
                dispatch(updateAppliedFilters(response.data.facets));
                if (response.data.selectedFilters) {
                    const parsedFilters = getFiltersData(response.data.selectedFilters, true);
                    const appliedFiltersData = initAppliedFilters(parsedFilters.allFilters);
                    dispatch(updateSSRFiltersList({ ...appliedFiltersData, isSSR: false }));
                }
                resolve(response.data);
            })
            .catch(() => {
                dispatch(fetchSimilarCarListFailure({error: HTTP_API_ERROR}));
                reject({error: HTTP_API_ERROR});
            });
    });
};

const fetchComparsionDetails = (queryString) => (dispatch, getState) => {
    const {
        user: {
            secureToken,
            isUserZeroDpVariant
        },
        tradeIn: {
            userTradeInData,
            tradeInToggleSelected
        }
    } = getState();
    // Trade-in toggle selected or not
    const { tradeIn = false } =  userTradeInData || {};
    const params = {
        zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP
    };
    if (tradeIn) {
        queryString += `&tradeIn=${!!tradeInToggleSelected}`;
    }
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchComparsionDetails(queryString, secureToken, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

const updateSSRStatus = (isSSR) => ({
    type: Types.UPDATE_SSR_STATUS,
    isSSR
});

const resetCarList = () => dispatch => {
    dispatch({ type: Types.RESET_CAR_LIST });
};

const reloadCarList = (shouldReloadList) => ({
    type: Types.RELOAD_CAR_LIST,
    shouldReloadList
});

const handleCarComparePopup = (showCarComparision) => ({
    type: Types.HANDLE_CAR_COMPARE_POPUP,
    showCarComparision
});

const addCarToCompare = (carDetails) => ({
    type: Types.ADD_CAR_TO_COMPARE,
    carDetails
});

const removeCarFromCompare = (carId) => ({
    type: Types.REMOVE_CAR_FROM_COMPARE,
    carId
});

const fetchCarCompareSuccess = (carCompareData) => ({
    type: Types.FETCH_CAR_COMPARE_SUCCESS,
    carCompareData
});

// Fetch FAQ Data
const fetchClpDataInit = () => ({
    type: Types.FETCH_FAQ_DATA
});

const fetchClpDataSuccess = (data, params) => ({
    type: Types.FETCH_FAQ_DATA_SUCCESS,
    data,
    params
});

const fetchClpDataFailure = (error) => ({ type: Types.FETCH_FAQ_DATA_FAILURE, error });

const fetchClpData = (params, queryString) => (dispatch, getState) => {
    dispatch(fetchClpDataInit());
    const {
        user: { secureToken, isUserZeroDpVariant, isGuestLogin }
    } = getState();
    params = {...params, zeroDpVariant: isUserZeroDpVariant ? ZERO_DP_VARIANTS.ZERO_DP : ZERO_DP_VARIANTS.NON_ZERO_DP };

    if (queryString && queryString.indexOf("userFinanceType") < 0) {
        queryString = `${queryString}&userFinanceType=${FINANCE_TYPE.CARS24}`;
    }
    return new Promise((resolve, reject) => {
        ListingServiceAU.fetchClpData(params, queryString, isGuestLogin ? null : secureToken)
            .then(response => {
                dispatch(fetchClpDataSuccess(response.data, params));
                resolve(response.data);
            }).catch(error => {
                dispatch(fetchClpDataFailure(error));
                reject(error);
            });
    });
};

const updateFinanceCentricListing = (showFinanceCentricListing) => ({
    type: Types.UPDATE_FINANCE_CENTRIC_LISTING,
    showFinanceCentricListing
});

const updateCrmVaraintListing = (showCrmVariant) => ({
    type: Types.UPDATE_CRM_VARIANT,
    showCrmVariant
});

const cmsConfigInit = () => ({
    type: Types.CMS_CONFIG_INIT
});

const cmsConfigSuccess = (data) => ({
    type: Types.CMS_CONFIG_SUCCESS,
    data
});

const cmsConfigFailure = (error) => ({
    type: Types.CMS_CONFIG_FAILURE,
    error
});

const fetchCmsConfig = (params) => async (dispatch) => {
    dispatch(cmsConfigInit());
    try {
        const response = await BffDataAU.getCmsContent(params);
        dispatch(cmsConfigSuccess(response.data));
    } catch (error) {
        dispatch(cmsConfigFailure(error));
    }
};

export {
    fetchCarList,
    fetchCarListV2,
    fetchSimilarCarListV2,
    updateSSRStatus,
    resetCarList,
    reloadCarList,
    handleCarComparePopup,
    addCarToCompare,
    removeCarFromCompare,
    fetchCarCompareSuccess,
    fetchComparsionDetails,
    fetchClpData,
    fetchCarSuggestions,
    setKbbBannerData,
    updateFinanceCentricListing,
    updateCrmVaraintListing,
    fetchCmsConfig
};
